.WebsitePage {
    padding-top: 60px;
    width: 100vw;
    height: auto;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.websiteHeadingSection {
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
}

.websiteInfoSection {
    margin: 0 auto;
    width: 50%;
    height: auto;
    padding: 25px;
    justify-content: center;
    align-items: center;
}

.websiteGrid {
    display: grid;
    grid-template-columns: 1;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
    row-gap: 50px;
}

.websiteInfoDiv {
    width: auto;
    height: 400px;
    background-image: url('../assets/images/MacBook-temp-web.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.imageHolder {
    position: absolute;
    width: 200px;
    height: 400px;
    background-image: url('../assets/images/iPhoneJPG.JPG');
    background-repeat: no-repeat;
    background-position-x: right;
    background-size: cover;
    left: 80%;
    margin-top: -55vh;
}

@media (min-height: 1080px) {
    .imageHolder {
        position: absolute;
        width: 250px;
        height: 500px;
        background-image: url('../assets/images/iPhoneJPG.JPG');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        left: 80%;
        margin-top: -45vh;
    }
}

@media (max-height: 840px) {
    .imageHolder {
        position: absolute;
        width: 250px;
        height: 500px;
        background-image: url('../assets/images/iPhoneJPG.JPG');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        left: 80%;
        margin-top: -75vh;
    }
}

.WebsiteMobileInfo {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
}

.websiteDarkCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #131313;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 25px;
    color: white;
}

.websiteLightCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    border-radius: 25px;
}

.cardGrid {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, auto);  /* 3 columns */
    grid-template-rows: repeat(3, auto);
    text-align: left;
}

.websiteHeading {
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    color: black;
}

.lightHeading {
    padding-left: 10px;
    font-size: 1rem;
    color: black;
}

.cardGridItem1 {
    font-size: 1rem;
    padding-left: 10px;
    color: black;
    padding-top: 0;
    margin-top: 0;
}

.cardGridItem2 {
    font-size: 1rem;
    padding-left: 10px;
    color: black;
    padding-top: 0;
    margin-top: 0;
}

.cardGridItem3 {
    font-size: 1rem;
    padding-left: 10px;
    color: black;
    padding-top: 0;
    margin-top: 0;
}

.cardGridItemHeading1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #562a1a;
    margin-top: 10px;
    margin-bottom: 18px;
}

.cardGridItemHeading2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #562a1a;
    margin-top: 10px;
    margin-bottom: 18px;
}

.cardGridItemHeading3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #562a1a;
    margin-top: 10px;
    margin-bottom: 18px;
}

.websiteColoredCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #562a1a;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 25px;
    color: white;
}

.MobileBanner {
    position: relative;
    text-align: left;
    left: 20%;
}

@media (min-width: 1400px) and (min-height: 1440px) {
    .imageHolder {
        position: absolute;
        width: auto;
        height: 600px;
        background-image: url('../assets/images/iPhoneJPG.JPG');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        left: 80%;
        margin-top: -45vh;
    }
    .MobileBanner {
        position: relative;
        text-align: left;
        left: 0%;
    }
}

@media (max-width: 1400px) and (max-height: 960px) {
    .imageHolder {
        position: absolute;
        width: 200px;
        height: 400px;
        background-image: url('../assets/images/iPhoneJPG.JPG');
        background-repeat: no-repeat;
        background-position-x: right;
        background-size: cover;
        left: 80%;
        margin-top: -45vh;
    }
    .MobileBanner {
        position: relative;
        text-align: left;
        left: 0%;
    }
}

@media (max-width: 1400px) {
    .imageHolder {
        position: relative;
        margin: 0 auto;
        width: 250;
        height: 500px;
        background-image: url('../assets/images/iPhoneJPG.JPG');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-x: center;
        left: 0;
        margin-top: 0;
    }
    .MobileBanner {
        display: none;
    }
}

.MobileBanner h1 {
    font-size: 2rem;
}

.MobileBanner p{
    font-size: 16px;
}

.mobileView {
    width: 200px;
    position: absolute;
    bottom: 2%;
    right: 2%;
    overflow-x: hidden;
}

@media screen and (max-width: 650px) {
    .websiteInfoSection {
        margin: 0 auto;
        width: 100%;
        height: auto;
        padding: 25px;
        padding-top: 0px;
        justify-content: center;
        align-items: center;
    }

    .websiteHeadingSection {
        width: 100%;
        height: auto;
        padding-top: 50px;
        padding-bottom: 0px;
        align-items: center;
        justify-content: center;
    }

    .websiteInfoDiv {
        margin: 0 auto;
        width: 300px;
        height: 400px;
        background-image: url('../assets/images/MacBook-temp-web.png');
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .websiteDarkCard {
        margin: 0 auto;
        width: 100%;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background-color: #131313;
        padding: 25px;
        text-align: left;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-radius: 25px;
        color: white;
    }

    .websiteColoredCard {
        margin: 0 auto;
        width: 100%;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background-color: #562a1a;
        padding: 25px;
        text-align: left;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-radius: 25px;
        color: white;
    }

    .websiteLightCard {
        margin: 0 auto;
        width: 100%;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        background-color: white;
        padding: 25px;
        text-align: left;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border-radius: 25px;
    }

    .cardGrid {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(3, auto);
        text-align: left;
    }

    .cardGridItem1 {
        font-size: 16px;
        padding-left: 10px;
        color: black;
        padding-top: 0;
        margin-top: 0;
    }

    .cardGridItem2 {
        font-size: 16px;
        padding-left: 10px;
        color: black;
        padding-top: 0;
        margin-top: 0;
    }

    .cardGridItem3 {
        font-size: 16px;
        padding-left: 10px;
        color: black;
        padding-top: 0;
        margin-top: 0;
    }
    
    .cardGridItemHeading1 {
        margin: 0;
        margin-top: 10px;
        padding-left: 10px;
        font-size: 18px;
        color: #562a1a;
    }

    .cardGridItemHeading2{
        margin: 0;
        margin-top: 10px;
        padding-left: 10px;
        font-size: 18px;
        color: #562a1a;
        grid-row: 3;
    }

    .cardGridItemHeading3 {
        margin: 0;
        margin-top: 10px;
        padding-left: 10px;
        font-size: 18px;
        color: #562a1a;
        grid-row: 5;
    }
}