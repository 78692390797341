*, *::before, *::after {
    box-sizing: border-box;
  }

  .datasec-section-holder {
    margin-top: 60px;
    width: 100%;
    height: auto;
    overflow-x: hidden;
  }

  .datasec-heading {
    padding: 25px;
    width: 40%;
    margin: 0 auto;
    font-size: 36px;
    font-weight: 700;
  }

  .datasec-info {
    padding: 25px;
    width: 40%;
    margin: 0 auto;
  }

  .datasec-info p {
    margin: 0;
    font-weight: 400;
    font-size: 18px;
  }

  .datasec-info h3 {
    margin-bottom: 0;
  }

  .horiz-divider {
    margin: 0 auto;
    width: 100%;
    height: 1px;
    background-color: #111111;
    margin-top: 35px;
  }

  .datasec-q {
    padding: 0px;
  }

  @media screen and (max-width: 800px) {
    .datasec-heading {
        padding: 25px;
        width: 80%;
        margin: 0 auto;
        font-size: 36px;
        font-weight: 700;
      }
    
      .datasec-info {
        padding: 25px;
        width: 80%;
        margin: 0 auto;
      }
  }