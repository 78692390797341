
.sitemap-page {
    width: 100%;
    min-height: 100%;
    padding-top: 60px;
    min-height: calc(100vh - 300px);
}

.sitemap-heading {
    width: 40%;
    margin: 0 auto;
    padding: 36px;
    padding: 25px;
}

.sitemap-list {
    width: 100%;
    margin: 0 auto;
    list-style-type: none;
    font-size: 18px;
    padding: 25px;
}

.sitemap-link {
    text-decoration: none;
    color: #3a68ff;
}

.sitemap-link:hover {
    text-decoration: underline;
}

.sitemap-list-grid {
    padding: 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, auto);  /* 3 columns */
    grid-template-rows: repeat(3, auto);
    width: 40%;
    height: auto;
}

@media screen and (max-width: 650px) {
    .sitemap-list-grid {
        padding: 0;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(3, auto);
        width: 100%;
        height: auto;
    }
}