*, *::before, *::after {
    box-sizing: border-box;
  }

  .Intro-holder {
    height: auto;
  }

  .grid-container {
    width: 100vw;
    height: 100%;
    display: grid;
    row-gap: 20px;
  }

  .gridItem {
    display: flex;
    flex-direction: column-reverse;
    vertical-align: middle;
    width: 100%;
    height: 80vh;
    background-color: black;
    align-items: center;
    justify-content: center;
  }

  .LandingPageSection {
    background-color: white;
  }

  .websiteImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .AppImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .introTextContainer {
    position: static;
    align-items: center;
    justify-content: end;
    top: 100px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .introHeading {
    margin: 0;
    font-size: 64px;
    color: white;
  }

  @media screen and (max-width: 650px) {
    .introHeading {
      margin: 0;
      font-size: 42px;
      color: white;
    }
  }

  .websiteSection {
    background-image: url('../assets/images/pexels-ivan-samkov-4238496.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .appSection {
    background-image: url('../assets/images/pexels-pavel-danilyuk-5496464.jpg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .appearanceSection {
    position: relative;
    overflow: hidden;
  }

  .appearanceSectionText {
    z-index: 1;
  }
  
  .appearanceSectionVideo {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    object-fit: cover;
    object-position: center;
  }

  .introSection {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  .introParagraph {
    color: white;
    font-size: 18px;
    margin: 0;
    font-weight: 700;
  }

  .LandingPageHeadingContainer {
    width: 100%;
    height: 100%;
    padding-top: 120px;
    text-align: center;
    align-items: center;
    justify-content: start;
    backdrop-filter: brightness(25%);
  }

  .LandingPageHeadingContainerVideo {
    width: 100%;
    height: 100%;
    padding-top: 120px;
    text-align: center;
    align-items: center;
    justify-content: start;
    backdrop-filter: brightness(50%);
  }

  .LandingPageHeading {
    margin: 0 auto;
    width: 60%;
    color: white;
    font-size: 18px;
    font-weight: 400;
  }

  .LandingPageHeadingLight {
    margin: 0 auto;
    width: 30%;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .link {
    align-items: center;
    justify-content: center;
    line-height: 25px;
  }

  .LandingPageHeadingbig {
    margin: 0 auto;
    width: 60%;
    color: white;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
  }

  .text-link {
    font-weight: 600;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    color: #3a68ff;
    font-size: 1.2rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .text-link :hover {
    color: #3a68ff;
    text-decoration: underline;
  }

  .infoSection {
    width: 100%;
    height: auto;
    padding: 25px;
    background-color: white;
    align-items: center;
    justify-content: center;
  }

  .infoSectionHolder {
    padding: 25px;
    margin: 0 auto;
    width: auto;
    padding: 40px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  @media (max-width: 1440px) {
    .infoSectionHolder {
      padding: 25px;
      margin: 0 auto;
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .serviceCard {
    font-weight: 500;
    padding: 25px;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    border-radius: 25px;
  }

  .reachableCard {
    font-weight: 500;
    padding: 25px;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    text-align: center;
  }

  @media (max-width: 1440px) {
    .reachableCard {
      margin-top: 40px;
      font-weight: 500;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 60%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 25px;
      text-align: center;
    }
    .serviceCard {
      font-weight: 500;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 60%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 25px;
      text-align: center;
    }
  }

  .LandingReachable {
    font-size: 19px;
  }

  .LandingService {
    font-size: 19px;
  }

  .LandingServiceHeading {
    font-size: 36px;
  }

  .LandingReachableHeading {
    font-size: 36px;
  }

  @media screen and (max-width:650px) {
    .infoSectionHolder {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }

    .serviceCard {
      font-weight: 500;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      border-radius: 25px;
    }
  
    .reachableCard {
      font-weight: 500;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 25px;
      text-align: center;
    }

    .LandingPageHeadingLight {
      margin: 0 auto;
      width: 90%;
      color: black;
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
    }
  }

  .playPauseholder {
    position: absolute;
    width: 100px;
    height: 100px;
    z-index: 995;
    right: -15px;
    bottom: -20px;
  }

  .playpauseButton {
    position: relative;
    color: transparent;
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 32px;
    height: 32px;
    z-index: 995;
  }

  .playpauseButton:hover {
    color: transparent;
    background-color: transparent;
  }

  @media screen and (max-width: 650px) {
    .LandingPageHeadingbig {
      margin: 0 auto;
      width: 80%;
      color: white;
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 15px;
    }

    .LandingPageHeading {
      margin: 0 auto;
      width: 80%;
      color: white;
      font-size: 16px;
      font-weight: 400;
    }

    .LandingLottie style{
      width: 100%;
      height: 100%;
    }

    .serviceCard {
      font-weight: 400;
      font-size: 14px;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      border-radius: 25px;
    }

    .reachableCard {
      font-weight: 400;
      font-size: 14px;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 25px;
      text-align: center;
    }
  }

  @media screen and (max-height: 450px) {
    .gridItem {
      display: flex;
      flex-direction: column-reverse;
      vertical-align: middle;
      width: 100%;
      height: auto;
      background-color: black;
      align-items: center;
      justify-content: center;
    }

    .LandingPageHeadingbig {
      margin: 0 auto;
      width: 80%;
      color: white;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 15px;
    }

    .LandingPageHeading {
      margin: 0 auto;
      width: 80%;
      color: white;
      font-size: 14px;
      font-weight: 600;
    }

    .introTextContainer {
      position: absolute;
      align-items: center;
      top: 100px;
      width: 100%;
      justify-content: center;
      text-align: center;
      z-index: 800;
    }

    .introHeading {
      margin: 0;
      font-size: 24px;
      color: white;
    }

    .introSection {
      height: auto;
      width: 100vw;
    }

    .serviceCard {
      font-weight: 400;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      border-radius: 25px;
    }

    .reachableCard {
      font-weight: 400;
      padding: 25px;
      align-items: center;
      justify-content: center;
      height: auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 25px;
      text-align: center;
    }

    .LandingPageHeadingLight {
      margin: 0 auto;
      width: 80%;
      color: black;
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
    }
  }