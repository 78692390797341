.impressum-page {
    width: 100%;
    min-height: 100%;
    padding-top: 60px;
    min-height: calc(100vh - 300px);
    height: auto;
}

.imp-heading {
    padding: 25px;
    width: 40%;
    position: relative;
    margin: 0 auto;
    font-size: 36px;
}

.impressum-lawtext {
    width: 40%;
    position: relative;
    margin: 0 auto;
    padding-left: 25px;
}

.impressum-lawtext a {
    width: 40%;
    position: relative;
    margin: 0 auto;
}

.imp-divider {
    height: 60px;
    width: 100%;
}

.impressum-lawtext-small-heading {
    padding: 25px;
    width: 40%;
    position: relative;
    margin: 0 auto;
    font-size: 18px;
}

@media screen and (max-width: 800px) {
    .imp-heading {
        width: 70%;
        position: relative;
        margin: 0 auto;
        padding: 0;
        font-size: 36px;
    }

    .impressum-lawtext {
        width: 70%;
        position: relative;
        margin: 0 auto;
        padding: 0;
    }

    .impressum-lawtext-small-heading {
        padding: 25px 0px 0px 0px;
        width: 70%;
        position: relative;
        font-size: 18px;
    }

    .imp-divider {
        height: 60px;
        width: 100%;
    }
}