.footer {
    background-color: #2f3640;
    color: white;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
    font-size: .8rem;
    text-align: center;
    position: relative;
    width: 100%;
    height: 300px;
  }

  .horiz-divider-footer {
    margin: 0 auto;
    width: 40%;
    height: 1px;
    background-color: white;
    margin-top: 35px;
  }

  .footer-content-holder {
    width: 50%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  .footer-text {
    margin: 0;
  }

  .footer p {
    text-align: center;
  }

  .footer ul {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    list-style-type: none;
    width: 100%;
    padding: 0;
    height: auto;
    gap: 14px;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width:650px) {


    .footer-content-holder {
      width: 100%;
      margin: 0 auto;
    }

    .footer p {
      text-align: center;
    }

    .footer ul {
      margin-top: 32px;
      margin-bottom: 32px;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      width: 100%;
      height: auto;
      gap: 14px;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
  }

  .footer ul li {
    border-right: white;
    border-width: 1px;
    border-right-style: solid;
  }

  .footer-last-link {
    border-right: none !important;
  }

  .footer-last-link-a {
    padding-right: 0px !important;
  }

  .footer ul li a {
    padding-right: 14px;
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    font-size: .8rem;
    transition: all 0.2s;
    position: relative;
  }
  
  .footer a:hover {
    color: grey;
    text-decoration: underline;
  }

  .footerHolder {
    min-height: 100%;
    overflow: auto;
  }

  .footerBlueLink {
    color: #3a68ff !important; 
  }

  .footerBlueLink:hover {
    text-decoration: underline;
    color: #3a68ff;
  }
  