*, *::before, *::after {
    box-sizing: border-box;
  }

.AppearancePage {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.appearanceGrid {
    display: grid;
        grid-template-columns: 1;
        grid-template-rows: auto;
        justify-content: center;
        align-items: center;
        row-gap: 50px;
}

.AppearanceHeadingHolder {
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
    align-items: center;
}

.AppearanceHeading {
    width: 30ch;
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    color: black;
    margin-bottom: 50px;
}

.appGridHeading{
    font-size: 16px;
    padding-left: 10px;
}

.appGridParagraph {
    padding-left: 10px;
    margin-bottom: 5ch;
}

.AppearanceSection {
    margin: 0 auto;
    width: 50%;
    height: auto;
    padding: 25px;
    justify-content: center;
    align-items: center;
}

.appearancecardGrid {
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, auto);  /* 3 columns */
    grid-template-rows: repeat(3, auto);
    text-align: left;
}

.appearancecardGridItem1 {
    font-size: 16px;
    padding-left: 10px;
    color: black;
    margin-top: 0;
}

.appearancecardGridItem2 {
    font-size: 16px;
    padding-left: 10px;
    color: black;
    margin-top: 0;
}

.appearancecardGridItem3 {
    font-size: 16px;
    padding-left: 10px;
    color: black;
    margin-top: 0;
}

.appearancecardGridItemHeading1 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #6ab04c;
    margin-top: 10px;
    margin-bottom: 18px;
}

.appearancecardGridItemHeading2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #6ab04c;
    margin-top: 10px;
    margin-bottom: 18px;
}

.appearancecardGridItemHeading3 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #6ab04c;
    margin-top: 10px;
    margin-bottom: 18px;
}

.appearanceLightCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: white;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 25px;
}

.appearanceDarkCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #131313;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 25px;
    color: white;
}

.appearanceDoubleCard {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);  /* 3 columns */
    grid-template-rows: repeat(1, auto);
    gap: 30px;
}

.appearanceColoredCard {
    width: 100%;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #5b7e4c;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
}

.appearanceSpan {
    color: #6ab04c;
}

.appearanceLastSection {
    width: 100%;
    height: auto;
    padding: 25px;
    justify-content: center;
    align-items: center;
}

.appearanceLightCardLottie {
    width: 30%;
    background-color: white;
    padding: 25px;
    text-align: left;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.AppearanceLottie {
    position: relative;
    margin: 0 auto;
}

.container_row{
    display: grid;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .layer1, .layer2{
    grid-column: 1;
    grid-row: 1;
  }

  .layer1 {
    margin: 0 auto;
    height: auto;
    width: 800px;
    display: flex;
    z-index: 800;
  }

  @media screen and (max-width:650px) {
    .AppearanceHeading {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        font-size: 24px;
        color: black;
    }

    .AppearanceParagraph {
        width: 90%;
        margin: 0 auto;
        text-align: center;
        font-size: 18px;
        color: black;
        margin-top: 15px;
    }

    .AppearanceSection {
        margin: 0 auto;
        width: 100%;
        height: auto;
        padding: 25px;
        justify-content: center;
        align-items: center;
    }

    .appearancecardGrid {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(3, auto);
        text-align: left;
    }
    
    .appearancecardGridItem1 {
        font-size: 16px;
        padding-left: 10px;
        color: black;
        margin-top: 0;
    }

    .appearancecardGridItem2 {
        font-size: 16px;
        padding-left: 10px;
        color: black;
        margin-top: 0;
    }

    .appearancecardGridItem3 {
        font-size: 16px;
        padding-left: 10px;
        color: black;
        margin-top: 0;
    }
    
    .appearancecardGridItemHeading1 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        color: #6ab04c;
        margin-top: 10px;
    }

    .appearancecardGridItemHeading2 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        color: #6ab04c;
        margin-top: 10px;
        grid-row: 3;
    }

    .appearancecardGridItemHeading3 {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        color: #6ab04c;
        margin-top: 10px;
        grid-row: 5;
    }

    .appearanceDoubleCard {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(2, auto);
        gap: 30px;
        margin-top: 50px;
        margin-bottom: 20px;
    }

    .container_row{
        display: grid;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
      
      .layer1, .layer2{
        grid-column: 1;
        grid-row: 1;
      }
    
      .layer1 {
        margin: 0 auto;
        height: auto;
        width: auto;
        display: flex;
        flex-direction: column;
        z-index: 800;
      }

      .appearanceLightCardLottie {
        margin: 0 auto;
        width: 100%;
        background-color: white;
        padding: 25px;
        text-align: left;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        z-index: 999;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .appearanceColoredCard {
        margin: 0 auto;
        width: 100%;
        background-color: #5b7e4c;
        padding: 25px;
        text-align: left;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
        color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .AppearanceLottie {
        display: none;
    }
  }

  @media screen and (max-width: 1500px) {
    .appearanceDoubleCard {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(2, auto);
        gap: 30px;
        margin-top: 50px;
        margin-bottom: 20px;
    }
  }
