*, *::before, *::after {
    box-sizing: border-box;
  }

.AboutPage {
    margin-top: 60px;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 300px);
}

.aboutInfo h1{
    font-size: 36px;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.aboutInfo p {
    margin: 0 auto;
    width: 50%;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 1.3rem;
    font-weight: 500;
}

.aboutInfo {
    margin: 0 auto;
    width: 100%;
    height: auto;
}

.aboutSection {
    padding: 50px;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
}

.aboutGrid {
    margin: 0 auto;
    width: 50%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(1, auto);  /* 3 columns */
    grid-template-rows: repeat(3, auto);
    gap: 35px;
}

.aboutPersonalCard {
    display: flex;
    width: 100%;
    height: 100%;
    color: black;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.aboutPersonalCard img{
    width: 30%;
    height: 100%;
    object-fit: cover;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 15px;
}

.quote {
    padding: 25px;
    font-size: 1.3rem;
}

@media screen and (max-width:650px) {
    .aboutInfo {
        margin: 0 auto;
        width: 100%;
        height: auto;
        text-align: left;
    }

    .aboutInfo h1{
        margin: 0 auto;
        padding-top: 25px;
        font-size: 32px;
        text-align: left;
        width: 80%;
    }
    
    .aboutInfo p {
        margin: 0 auto;
        width: 80%;
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: 18px;
        font-weight: 500;
    }

    .aboutSection {
        padding: 50px;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    
    .aboutGrid {
        margin: 0 auto;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(3, auto);
        gap: 35px;
    }

    .aboutPersonalCard {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        color: black;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    
    .aboutPersonalCard img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0px;
    }
}

@media screen and (max-width:900px) {
    .aboutInfo {
        margin: 0 auto;
        width: 100%;
        height: auto;
        text-align: left;
    }

    .aboutInfo h1{
        margin: 0 auto;
        padding-top: 25px;
        font-size: 32px;
        text-align: left;
        width: 80%;
    }
    
    .aboutInfo p {
        margin: 0 auto;
        width: 80%;
        margin-top: 50px;
        margin-bottom: 50px;
        font-size: 18px;
        font-weight: 500;
    }

    .aboutSection {
        padding: 50px;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
    }
    
    .aboutGrid {
        margin: 0 auto;
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: repeat(1, auto);  /* 3 columns */
        grid-template-rows: repeat(3, auto);
        gap: 35px;
    }

    .aboutPersonalCard {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 80%;
        color: black;
        border-radius: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    
    .aboutPersonalCard img{
        width: 100%;
        height: 50%;
        object-fit: cover;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        border-bottom-left-radius: 0px;
    }
}