*, *::before, *::after {
    box-sizing: border-box;
  }

.AppPage {
    padding-top: 60px;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    background-color: #2d3436;
}

.appHeadingHolder {
    width: 100%;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 50px;
    padding-right: 50px;
    justify-content: center;
    align-items: center;
}

.AppHeading {
    margin: 0 auto;
    text-align: center;
    font-size: 36px;
    color: white;
}

.visible {
    display: block;
}

.AppInfoSection {
    width: 100%;
    height: auto;
    padding: 25px;
    align-items: center;
    justify-content: center;
}

.AppGrid {
    margin: 0 auto;
    display: grid;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1;
    grid-template-rows: 3;
    gap: 25px;
    color: white;
}

.coloredcardHolder {
    width: 100%;
    height: auto;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.coloredAppCard {
    width: 47.5%;
    background-color: #3b469c;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
}

.singleCardApp {
    height: 100%;
    background-color: #3b469c;
    padding: 25px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 25px;
    width: 100%;
}

.appInfoText {
    font-size: 1rem;
    color: white;
    height: 100%;
}


.Iconholder {
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
}

.rotate-image {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.app-code-heading {
    background-color: #3b469c;
    border-radius: 15px;
    color: white;
    padding: 1px 10px;
}

.rotateButton {
    border: none;
    color: transparent;
    background-color: transparent;
    position: relative;
    left: 90%;
}

.app-heading-icon {
    margin-left: auto;
}

.expandableParagraph {
    margin-bottom: 80px;
    border-top: white solid 1px;
    padding-top: 5px;
}

.expandableLightCard {
    position: sticky;
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
    text-align: left;
    border-radius: 25px;
}

.expandablecardHeadingHolder {
    width: 100%;
    height: auto;
    padding: 25px;
}

.expandableGrid {
    width: 100%;
    height: auto;
    padding-top: 15;
    padding-bottom: 15;
    display: grid;
    padding: 25px;
    grid-template-columns: 1;
    grid-template-rows: 6;
    gap: 20px;
}

.app-span-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.alwaysOnSpan {
    font-weight: bold;
    font-size: 1rem;
}

.expandedSpan {
    display: block;
    font-size: 1rem;
}

@media screen and (max-width: 650px) {
    .AppGrid {
        margin: 0 auto;
        display: grid;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1;
        grid-template-rows: 3;
        gap: 25px;
    }

    .coloredcardHolder {
        width: 100%;
        height: auto;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
    }
    
    .coloredAppCard {
        width: 100%;
        background-color: #3b469c;
        padding: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 25px;
    }
}

@media screen and (max-width: 1300px) {

    .coloredcardHolder {
        width: 100%;
        height: auto;
        vertical-align: middle;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
    }
    
    .coloredAppCard {
        margin: 0 auto;
        width: 100%;
        background-color: #3b469c;
        padding: 25px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        border-radius: 25px;
    }
}


